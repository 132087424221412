<template>
  <div
    v-if="showTemplates"
    :class="$style.templatesList"
  >
    <div :class="[$style.menu, { [$style['menu--mobile']] : isMobileMenuShowed }]">
      <button
        type="button"
        :class="[
          'button',
          'is-ghost',
          'has-text-weight-bold',
          'has-text-grey-dark',
          $style.btn,
        ]"
        @click="toggleMobileMenu"
      >
        Categories
        <span :class="$style.btn__icon">
          <font-awesome-icon :icon="['fal', 'chevron-down']" />
        </span>
      </button>

      <ul :class="$style.menu__list">
        <li
          v-for="{ shortName, name, id } in sortedCategories"
          :key="shortName"
          :class="[$style.item, selectedCategory === id && $style['item--selected']]"
          @click="selectedCategory = id"
        >
          {{ shortName || name }}
        </li>
      </ul>
    </div>

    <b-input
      ref="control"
      v-model="searchString"
      :class="$style.input"
      icon="magnifying-glass"
      placeholder="Search template"
    />

    <div :class="$style.cards">
      <card
        v-for="item in templatesPaginated"
        :key="item.id"
        :template="item"
        @click.native="select(item)"
      />
    </div>

    <div
      v-if="showLoadMore"
      :class="$style.loadMore"
    >
      <b-button
        type="is-primary"
        @click="loadMore"
      >
        Load more
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useTemplatesStore, useHelpStore } from '@/stores/index.js';
import Card from './components/template-card.vue';
import { sortByPriority, searchWith } from '@/utils/index.js';

export default {
  name: 'TemplatesList',
  components: {
    Card,
  },
  data: () => ({
    searchString: '',
    selectedCategory: 'all',
    templateLimit: 24,
    isMobileMenuShowed: false,
  }),
  computed: {
    ...mapState(useTemplatesStore, ['templates', 'templatesCategories']),

    showTemplates() {
      return this.templates && this.templatesCategories;
    },
    showLoadMore() {
      return this.templatesToDisplay.length > 24
        && this.templatesToDisplay.length !== this.templatesPaginated.length;
    },
    templatesByCategory() {
      return this.selectedCategory === 'all' ? this.templates : this.templates
        .filter(({ categories }) => categories
          .map(({ id }) => id)
          .some((c) => c.includes(this.selectedCategory)));
    },
    templateBySearchString() {
      const searchByFields = ({
        description,
        name,
        slug,
        type: { label },
      }) => ({
        description,
        name,
        slug,
        label,
      });

      return searchWith(this.searchString)(searchByFields)(this.templatesByCategory);
    },
    templatesToDisplay() {
      return sortByPriority(this.templateBySearchString);
    },
    templatesPaginated() {
      return this.templatesToDisplay.slice(0, this.templateLimit);
    },
    sortedCategories() {
      const allItem = this.templatesCategories.find((x) => x.id === 'all') || {};
      const otherItems = this.templatesCategories.filter((x) => x.id !== 'all') || [];

      return [allItem, ...sortByPriority(otherItems)];
    },
  },
  watch: {
    showTemplates(value) {
      return value && this.$nextTick(() => this.$refs.control?.focus());
    },
  },
  async created() {
    await Promise.all([this.readTemplates(), this.readTemplatesCategories()]);

    this.setHelpOpen(true);
  },
  beforeDestroy() {
    this.setHelpOpen(false);
  },
  methods: {
    ...mapActions(useHelpStore, { setHelpOpen: 'setOpen' }),
    ...mapActions(useTemplatesStore, ['readTemplates', 'readTemplatesCategories']),

    select({ slug }) {
      return this.$router.push({ name: 'DocumentsProcessCreateFromTemplate', params: { slug } });
    },
    loadMore() {
      this.templateLimit += 24;
    },
    toggleMobileMenu() {
      this.isMobileMenuShowed = !this.isMobileMenuShowed;
    },
  },
};
</script>

<style lang="scss" module>
.templatesList {
  display: grid;
  grid-template-columns: 128px minmax(190px, 650px);
  grid-gap: 32px;
  grid-template-areas:
    "menu input"
    "menu cards";

  .menu {
    grid-area: menu;

    &__list {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    .btn {
      display: none;
      align-items: baseline;
      padding: 0;
      height: auto;

      &:hover, &:focus:not(:active) {
        box-shadow: none;
      }

      &__icon {
        margin-left: 7px;
      }
    }

    .item {
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 10px;
      }

      &--selected {
        font-weight: 600;
      }
    }
  }

  .input {
    grid-area: input;
  }

  .cards {
    grid-area: cards;
    display: grid;
    justify-content: space-between;
    grid-gap: 15px 40px;
    grid-template-columns: repeat(3, 190px);
  }

  .loadMore {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}

@media (max-width: 1100px) {
  .templatesList {
    grid-template-columns: 128px minmax(190px, 420px);
    .cards {
      grid-template-columns: repeat(2, 190px);
    }
  }
}

@media (max-width: 768px) {
  .templatesList {
    grid-template-columns: minmax(190px, 650px);
    grid-template-areas:
    "input"
    "menu"
    "cards";
    margin: 0 auto;

    .menu {
      &__list {
        display: none;
        margin-top: 10px;
      }

      &--mobile {
        .btn__icon {
          display: inline-block;
          transform: rotate(180deg);
        }

        .menu__list {
          display: flex;
        }
      }

      .btn {
        display: flex;
      }
    }

    .cards {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (max-width: 660px) {
  .templatesList {
    padding: 0 10px;
    .cards {
        grid-template-columns: repeat(2, 1fr);
      }
  }
}

@media (max-width: 480px) {
  .templatesList {
    grid-gap: 20px;
    padding: 0 20px;
    .cards {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
}
</style>
