var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showTemplates)?_c('div',{class:_vm.$style.templatesList},[_c('div',{class:[_vm.$style.menu, { [_vm.$style['menu--mobile']] : _vm.isMobileMenuShowed }]},[_c('button',{class:[
        'button',
        'is-ghost',
        'has-text-weight-bold',
        'has-text-grey-dark',
        _vm.$style.btn,
      ],attrs:{"type":"button"},on:{"click":_vm.toggleMobileMenu}},[_vm._v(" Categories "),_c('span',{class:_vm.$style.btn__icon},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'chevron-down']}})],1)]),_c('ul',{class:_vm.$style.menu__list},_vm._l((_vm.sortedCategories),function({ shortName, name, id }){return _c('li',{key:shortName,class:[_vm.$style.item, _vm.selectedCategory === id && _vm.$style['item--selected']],on:{"click":function($event){_vm.selectedCategory = id}}},[_vm._v(" "+_vm._s(shortName || name)+" ")])}),0)]),_c('b-input',{ref:"control",class:_vm.$style.input,attrs:{"icon":"magnifying-glass","placeholder":"Search template"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('div',{class:_vm.$style.cards},_vm._l((_vm.templatesPaginated),function(item){return _c('card',{key:item.id,attrs:{"template":item},nativeOn:{"click":function($event){return _vm.select(item)}}})}),1),(_vm.showLoadMore)?_c('div',{class:_vm.$style.loadMore},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":_vm.loadMore}},[_vm._v(" Load more ")])],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }