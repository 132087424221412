<template>
  <div :class="$style.card">
    <div
      :class="[$style.image, isLoaded && $style['image--loaded']]"
    >
      <img
        :src="template.thumbnailUrl"
        :alt="template.name"
        @load="onImageLoad"
      >
      <div :class="$style.type">
        {{ template.type.label }}
      </div>
    </div>

    <div :class="$style.content">
      {{ template.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    template: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  methods: {
    onImageLoad() {
      return (this.isLoaded = true);
    },
  },
};
</script>

<style lang="scss" module>
.card {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  cursor: pointer;

  .image {
    position: relative;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    border: 1px solid var(--color-gray);
    border-radius: 2px;
    height: 240px;
    transition-duration: var(--transition-duration);

    img {
      display: block;
      object-fit: contain;
      object-position: center;
      opacity: 0;
    }

    &--loaded {
      height: initial;

      img {
        opacity: 1;
      }
    }
  }

  .type {
    position: absolute;
    right: 5%;
    bottom: 5%;
    border: 1px solid var(--color-gray);
    border-radius: 2px;
    padding: 2px;
    font-size: 9px;
    background-color: var(--color-white);
  }

  .content {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    min-height: 72px;
    font-weight: 500;
    text-align: center;
  }

  &:hover {
    .image {
      border: 1px solid var(--color-gray-dark);
    }
  }
}
</style>
